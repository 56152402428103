import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="border-t border-t-slate-200 pt-6">
      <div className="w-full flex items-center justify-between gap-4 flex-wrap">
        <p className="text-sm font-medium text-slate-500">
          ©2023 - {new Date().getFullYear()} {t('copy-rights')}
        </p>
        <p className="text-sm font-medium text-slate-500">
          {t('made-with-love')}{' '}
          <Link
            to="https://ia6g.com"
            target="_blank"
            className="text-primary font-semibold"
          >
            Ia6g - بوابة التطبيقات العالمية
          </Link>
        </p>
      </div>
    </footer>
  );
}
