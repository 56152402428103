import { Icon } from '@iconify/react';
import React, { AllHTMLAttributes, FunctionComponent, ReactNode } from 'react';
import SearchBox from '../SearchBox';
import Card from '../Card';
import Pagination from '../Pagination';
import { useTranslation } from 'react-i18next';

interface TableInterface {
  loading?: boolean;
  isEmpty?: boolean;
  pagination?: any;
  onNextClick?: AllHTMLAttributes<HTMLButtonElement>['onClick'];
  onPreviousClick?: AllHTMLAttributes<HTMLButtonElement>['onClick'];
  searchProps?: AllHTMLAttributes<HTMLInputElement>;
  RenderBody: FunctionComponent;
  RenderHead: FunctionComponent;
  Actions?: FunctionComponent;
  title?: string;
  hasSearch?: boolean;
}

function Table({
  loading,
  pagination,
  searchProps,
  onNextClick,
  onPreviousClick,
  RenderBody,
  RenderHead,
  isEmpty,
  Actions,
  title,
  hasSearch = true
}: TableInterface) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4 !p-0">
      {hasSearch && pagination && (
        <div className="flex gap-4 justify-between flex-wrap p-4 pb-0">
          <SearchBox
            loading={loading}
            onChange={searchProps?.onChange}
            onKeyDown={searchProps?.onKeyDown}
            defaultValue={searchProps?.defaultValue}
            value={searchProps?.value}
            placeholder={searchProps?.placeholder}
            className={searchProps?.className}
          />
          <div className="inline-flex gap-2 items-center flex-wrap">
            {Actions ? <Actions /> : null}
          </div>
        </div>
      )}
      <div className="grid">
        <div className="overflow-x-auto">
          <table className="styled-table">
            <thead className="bg-gray-100">
              <RenderHead />
            </thead>
            <tbody>
              <RenderBody />

              {isEmpty ? (
                <tr>
                  <td colSpan={100}>
                    <p className="text-gray-500 flex-1 shrink-0 text-sm text-center">
                      {t('no-data')}
                    </p>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
      {pagination ? (
        <Pagination
          pagination={pagination}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
        />
      ) : null}
    </Card>
  );
}

export default Table;
