import { Icon } from '@iconify/react';
import React, { AllHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps extends AllHTMLAttributes<HTMLInputElement> {
  loading?: boolean;
  children?: React.ReactNode;
  [key: string]: any;
}

export default function SearchBox({
  loading = false,
  className,
  children,
  placeholder,
  ...props
}: IProps) {
  const icon = loading ? 'svg-spinners:3-dots-move' : 'ri:search-line';
  const { t } = useTranslation();

  return (
    <div
      className={[
        'inline-flex gap-3 items-center px-3 rounded-lg border border-gray-200 bg-white shadow-2xl shadow-gray-800/5',
        className
      ].join(' ')}
    >
      <Icon
        icon={icon}
        width="18"
        className="text-gray-600"
      />
      <input
        type="text"
        placeholder={placeholder || t('placeholder')}
        className="py-3  text-sm placeholder:text-gray-500 bg-transparent flex-1"
        {...props}
      />
      {children}
    </div>
  );
}
