import { useState } from 'react';
import { Switch } from '@headlessui/react';
import DotStatus from './DotStatus';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import { AxiosError } from 'axios';

interface IProps {
  pathname: string;
  refetch: () => Promise<any>;
  active?: boolean;
}

export default function ChangeStatus({ pathname, refetch, active = false }: IProps) {
  const [enabled, setEnabled] = useState<boolean>(active);
  const [disabled, setDisabled] = useState<boolean>(false);

  async function onStatusChange() {
    try {
      setDisabled(true);
      const { data } = await axiosInstance.post(pathname);
      generateAlert(data.message, 'success');
      await refetch();
    } catch (error: AxiosError | any) {
      const err = error.response?.data;
      const message = err?.message;
      generateAlert(message, 'error');
    } finally {
      setDisabled(false);
    }
  }

  return (
    <div>
      <div className="flex items-center justify-between gap-2 text-sm text-gray-500">
        <DotStatus active={enabled} />
        <Switch
          checked={enabled}
          onChange={onStatusChange}
          className={`${
            enabled ? 'bg-teal-600' : 'bg-red-500'
          } relative inline-flex h-6 w-11 items-center rounded-full`}
          disabled={disabled}
        >
          <span className="sr-only">Change status</span>
          <span
            className={`${
              enabled ? 'translate-x-6 rtl:-translate-x-6' : 'translate-x-1 rtl:-translate-x-1'
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
    </div>
  );
}
