import { Icon } from '@iconify/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'src/components/shared/Alert';
import InputFile from 'src/components/shared/InputFile';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import { useAlert } from 'src/hooks/alerts';
import useForm from 'src/hooks/useForm';
import { RootState } from 'src/store';

function Profile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [profile, setProfile] = React.useState<any>(user);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);

  const GetProfile = React.useCallback(async function () {
    try {
      const { data } = await axiosInstance.get('profile');
      console.log(data);
    } catch (error) {
      console.log('error while getting user info\n', error);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="p-6 space-y-4 grid">
        <div className="relative rounded-lg  bg-white ring-1 ring-gray-200 shadow-2xl shadow-gray-600/5 p-6">
          <UpdateFormBody
            reFetching={GetProfile}
            schema={profile}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

interface FormInterface {
  name: string | undefined;
  email: string | undefined;
  mobile: string | undefined;
  image: string | undefined;
  password: string | undefined;
  c_password: string | undefined;
}

const UpdateFormBody = ({
  schema,
  reFetching
}: {
  reFetching: any;
  schema: FormInterface & { id: string };
}): JSX.Element => {
  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<typeof schema | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const alert = useAlert();

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    setErrors(undefined);
    setDisabled(true);

    let fd: FormData;
    fd = new FormData();

    for (const key in values) {
      const item = values[key as keyof typeof schema];

      if (item) {
        if (key === 'image') {
          if (Object.getPrototypeOf(item).constructor.name === 'File') {
            fd.append(key, item, item?.name);
          } else {
            fd.delete(key);
          }
        } else {
          fd.append(key, item);
        }
      }
    }

    setDisabled(true);
    prepareRequest(
      {
        url: 'profile/update',
        data: fd,
        method: 'post'
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert(data.message, 'success');
        await reFetching();
        helper.resetForm();
      }
    ).finally(() => {
      setDisabled(false);
    });
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });
  const { t } = useTranslation();

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      {errors ? (
        <Alert
          title="Error"
          type="error"
          content={errors}
        />
      ) : null}
      <div className="form-group">
        <label
          htmlFor="image"
          className="form-label"
        >
          {t('image')}
        </label>
        <InputFile
          defaultValue={formik.values.image instanceof File ? formik.values.image : null}
          onValueChange={function (e: any): void {
            formik.setFieldValue('image', e);
          }}
          accept="image/*"
        />
        {errors?.image ? <span className="form-error">{errors?.image}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          onChange={(e) => handleChange('name', e)}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('email')}</label>
        <input
          type="email"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.email}
          onChange={(e) => handleChange('email', e)}
        />
        {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('mobile')}</label>
        <input
          type="tel"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.mobile}
          onChange={(e) => handleChange('mobile', e)}
        />
        {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('current-password')}</label>
        <input
          type="password"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.c_password}
          onChange={(e) => handleChange('c_password', e)}
        />
        {errors?.c_password ? <span className="form-error">{errors?.c_password}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('new-password')}</label>
        <input
          type="password"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.password}
          onChange={(e) => handleChange('password', e)}
        />
        {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
      </div>
      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('save-changes')}</span>
          )}
        </button>
      </div>
    </form>
  );
};

export default Profile;
