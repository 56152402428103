import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SharedTime from 'src/components/shared/SharedTime';
import Title from 'src/components/shared/Title';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';
import { RootState } from 'src/store';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from '../components/shared/MiniDashboardSingleCard';

const Home: FC = (): JSX.Element => {
  const { t } = useTranslation();

  const globalCards: ItemInterface[] = [
    {
      icon: 'basil:invoice-outline',
      color: 'text-sky-500',
      title: t('dashboard-mini.total-reservations'),
      key: 'bookings_count',
      value: 0
    },

    {
      icon: 'basil:invoice-outline',
      color: 'text-sky-500',
      title: t('reservations'),
      key: 'today_bookings_count',
      value: 0
    },

    {
      icon: 'basil:invoice-outline',
      color: 'text-sky-500',
      title: t('services'),
      key: 'services_count',
      value: 0
    },
    {
      icon: 'basil:invoice-outline',
      color: 'text-sky-500',
      title: t('dashboard-mini.today-services'),
      key: 'today_services_count',
      value: 0
    },

    {
      icon: 'solar:users-group-rounded-bold-duotone',
      color: 'text-teal-500',
      title: t('experts'),
      key: 'employees_count',
      value: 0
    },

    {
      icon: 'ci:users',
      color: 'text-teal-500',
      title: t('call-centers'),
      key: 'call_centers_count',
      value: 0
    },
    {
      icon: 'ci:users',
      color: 'text-teal-500',
      title: t('dashboard-mini.month-clients'),
      key: 'clients_count',
      value: 0
    }
  ];

  let rerender: boolean = true;
  const queryRef = React.useRef(null);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<any>({});
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<string | undefined>(undefined);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [cards, setCards] = React.useState<any[]>(globalCards);
  const [pagination, setPagination] = React.useState<any>({
    page: 1,
    is_active: '',
    search_key: ''
  });
  const memoPaginate = React.useMemo(() => pagination, [pagination]);
  const { user } = useSelector((state: RootState) => state.auth);

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()])
        .then()
        .catch((error) => {
          console.log('fetching error..');
        })
        .finally(() => {
          setIsLoading(false);
        });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function () {
    prepareRequest(
      {
        url: 'dashboard',
        params: {
          center_id: user.center?.id
        }
      },
      (data) => {
        const { summary, ...others } = data?.result;
        setCards(
          cards.map((ev: ItemInterface) => {
            return { ...ev, value: summary[ev.key as string] || '-' };
          })
        );
        setResult(others);
      }
    );
  }, []);
  return (
    <React.Fragment>
      <div className="p-6 space-y-4">
        <div className="grid grid-wrapper gap-3">
          {cards.map((item, i) => (
            <MiniDashboardSingleCard
              key={i}
              item={item}
            />
          ))}
        </div>
        {/* <Title title={t('center-summary')} />
       <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-3">
          <Card className="space-y-4">
            <ul className="divide-y divide-y-gray-200">
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title
                    className="font-bold !text-gray-800"
                    title={t('reservations')}
                  />
                  <Title title={t('count')} />
                  <Title
                    title={t('total')}
                    className="text-end"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('today-reservations')} />
                  <Title title={result.bookings?.today?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.bookings?.today?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('upcoming-reservations')} />
                  <Title title={result.bookings?.upcoming?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.bookings?.upcoming?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('opened-reservations')} />
                  <Title title={result.bookings?.opened?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.bookings?.opened?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('completed-reservations')} />
                  <Title title={result.bookings?.completed?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.bookings?.completed?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('closed-reservations')} />
                  <Title title={result.bookings?.invoiced?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.bookings?.invoiced?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('cancelled-reservations')} />
                  <Title title={result.bookings?.cancelled?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.bookings?.cancelled?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
            </ul>
          </Card>
          <Card className="space-y-4">
            <ul className="divide-y divide-y-gray-200">
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title
                    className="font-bold !text-gray-800"
                    title={t('services')}
                  />
                  <Title title={t('count')} />
                  <Title
                    title={t('total')}
                    className="text-end"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('today-services')} />
                  <Title title={result.services?.today?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.services?.today?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('upcoming-services')} />
                  <Title title={result.services?.upcoming?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.services?.upcoming?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('opened-services')} />
                  <Title title={result.services?.opened?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.services?.opened?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('completed-services')} />
                  <Title title={result.services?.completed?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.services?.completed?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('closed-services')} />
                  <Title title={result.services?.invoiced?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.services?.invoiced?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-3 gap-2">
                  <Title title={t('cancelled-services')} />
                  <Title title={result.services?.cancelled?.count || 0} />
                  <Title
                    title={CurrencyFormatter(result.services?.cancelled?.total || 0)}
                    className="text-end text-gray-800 font-semibold"
                  />
                </div>
              </ItemList>
            </ul>
          </Card>
        </div> */}

        <Title title={t('recent-reservations')} />
        <Table
          RenderHead={() => {
            return (
              <tr>
                <th>#</th>
                <th>{t('client')}</th>
                <th>{t('cashier')}</th>
                <th>{t('paid-amount')}</th>
                <th>{t('total')}</th>
                <th>{t('booking-time')}</th>
              </tr>
            );
          }}
          RenderBody={() => {
            return (
              <>
                {result?.recent_bookings?.map((item: any, index: string | number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Link
                          className="text-blue-600"
                          to={'/bookings/' + item.id}
                        >
                          {item.id || '-'}
                        </Link>
                      </td>
                      <td>{item?.client?.name || '-'}</td>
                      <td>{item?.cashier?.name || '-'}</td>
                      <td>{CurrencyFormatter(item?.paid_amount || 0)}</td>
                      <td>{CurrencyFormatter(item?.total || 0)}</td>

                      <td>
                        <SharedTime
                          date={item.booking_time}
                          format="DD-MM-yyyy h:mm A"
                        />
                      </td>
                    </tr>
                  );
                })}
              </>
            );
          }}
          isEmpty={!result?.recent_bookings?.length}
        />
        <Title title={t('recent-services')} />
        <Table
          RenderHead={() => {
            return (
              <tr>
                <th>#</th>
                <th>{t('name')}</th>
                <th>{t('client')}</th>
                <th>{t('expert')}</th>
                <th>{t('price')}</th>
                <th>{t('status')}</th>
                <th>{t('booking-time')}</th>
              </tr>
            );
          }}
          RenderBody={() => {
            return (
              <>
                {result?.recent_services?.map((item: any, index: string | number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Link
                          className="text-blue-600"
                          to={'/bookings/' + item.booking?.id}
                        >
                          {item.booking?.id || '-'}
                        </Link>
                      </td>
                      <td>{item.name || '-'}</td>
                      <td>{item.booking?.client?.name || '-'}</td>
                      <td>{item.employee?.name || '-'}</td>
                      <td>{CurrencyFormatter(item.price || 0)}</td>
                      <td>{t('statuses.' + item.status)}</td>

                      <td>
                        <SharedTime
                          date={item.booking?.booking_time}
                          format="DD-MM-yyyy h:mmA"
                        />
                      </td>
                    </tr>
                  );
                })}
              </>
            );
          }}
          isEmpty={!result?.recent_services?.length}
        />
      </div>
    </React.Fragment>
  );
};

export default Home;
