export default function CurrencyFormatter(value: number): string {
  const lang = localStorage.getItem('@lang') || 'ar';

  if (!value) return '-';

  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'SAR',
    currencyDisplay: 'code'
    // currencySign: 'accounting'
  }).format(value);
}
