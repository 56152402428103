import React from 'react';

const Title = ({
  title,
  className,
  ...props
}: { title: string } & React.HTMLAttributes<HTMLParagraphElement>) => (
  <p
    className={['text-sm text-gray-500 text-start', className].join(' ')}
    {...props}
  >
    {title}
  </p>
);

export default Title;
