export interface TitleInterface {
  [key: string]: string;
}

export const titles: TitleInterface = {
  '/': 'dashboard',
  '/account/login': 'Login',
  '/centers': 'centers',
  '/tutorials': 'tutorials',
  '/tutorial-categories': 'tutorial-categories',
  '/packages': 'packages',
  '/subscriptions': 'subscriptions',

  '/activity-logs': 'logs',

  '/account/profile': 'profile',
  '/settings': 'settings',

  '*': 'page-not-found'
};
