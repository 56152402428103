import { Popover } from '@headlessui/react';
import React from 'react';

type Position = 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';

export default function Dropdown({
  button,
  children,
  position
}: {
  button: React.ReactNode;
  children: React.ReactNode;
  position: Position;
}) {
  const positions: Record<Position, string> = {
    'top-right': 'top-full mt-2 right-0 rtl:right-auto rtl:left-0',
    'top-left': 'top-full mt-2 left-0 rtl:left-auto rtl:right-0',
    'bottom-right': 'bottom-full mb-2 right-0 rtl:right-auto rtl:left-0',
    'bottom-left': 'bottom-full mb-2 left-0 rtl:left-auto rtl:right-0'
  };
  return (
    <Popover className="relative">
      <Popover.Button className="btn-with-icon outline-btn !text-gray-600 shrink-0">
        {button}
      </Popover.Button>

      <Popover.Panel
        className={[
          'absolute z-10  ring-1 ring-slate-200 bg-white rounded-lg w-max',
          positions[position]
        ].join(' ')}
      >
        {children}
      </Popover.Panel>
    </Popover>
  );
}
