import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Location, useLocation } from 'react-router-dom';
import { RootState } from 'src/store';

export default function ScrollToTop(): any {
  const { pathname }: Location = useLocation();
  const { token } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    // console.log(pathname, titles);
    // document.title = titles[pathname] ?? 'SA Beauty';
  }, [pathname]);

  return null;
}
