import { Icon } from '@iconify/react';

function LoadingComponent() {
  return (
    <div className="fixed inset-0 w-screen min-h-screen bg-white flex items-center justify-center p-8 z-50">
      <div className="shrink-0 flex flex-col justify-center items-center">
        <Icon
          icon="svg-spinners:3-dots-fade"
          width={50}
          className="text-primary"
        />
      </div>
    </div>
  );
}

export default LoadingComponent;
