import { RouteObject } from 'react-router-dom';
import {
  Admins,
  AdvancedGlobalSettings,
  Centers,
  ErrorPage,
  Home,
  Login,
  Packages,
  Profile,
  Subscriptions,
  TutorialCategories,
  Tutorials
} from 'src/screens';

type Route = RouteObject & {
  permission?: string | string[];
  breadcrumb?: any;
};

const globalRoutes: Route[] = [
  {
    path: '*',
    element: <ErrorPage />
  }
];

const authenticationRoutes: Route[] = [
  {
    path: '/account/login',
    element: <Login />
  },

  ...globalRoutes
];

const routes: Route[] = [
  {
    path: '/',
    element: <Home />,
    permission: 'dashboard'
  },

  // {
  //   path: '/activity-logs',
  //   element: <ActivityLogs />,
  //   permission: 'logs'
  // },

  {
    path: '/centers',
    element: <Centers />,
    permission: 'centers'
  },

  {
    path: '/packages',
    element: <Packages />,
    permission: 'packages'
  },
  {
    path: '/tutorials',
    element: <Tutorials />,
    permission: 'tutorials'
  },
  {
    path: '/tutorial-categories',
    element: <TutorialCategories />,
    permission: 'tutorial-categories'
  },
  {
    path: '/subscriptions',
    element: <Subscriptions />,
    permission: 'subscriptions'
  },

  {
    path: '/account/profile',
    element: <Profile />,
    permission: 'profile'
  },
  {
    path: '/settings/admins',
    element: <Admins />,
    permission: 'admins'
  },

  {
    path: '/settings/advanced',
    element: <AdvancedGlobalSettings />,
    permission: 'settings'
  },

  ...globalRoutes
];

export { authenticationRoutes, globalRoutes, routes };
