import { Icon } from '@iconify/react';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AuthWrapper from 'src/components/account/AuthWrapper';
import Card from 'src/components/shared/Card';
import Footer from 'src/components/shared/Footer';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import { saveUserInfo, storeToken } from 'src/reducers/auth';

interface AuthInterface {
  email_mobile?: string;
  password?: string;
}

const FormBody: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const globalValues = {
    email_mobile: undefined,
    password: undefined
  } satisfies AuthInterface;
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState<AuthInterface>(globalValues);
  const [errors, setErrors] = React.useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit(values, formikHelpers) {
      setDisabled(true);
      prepareRequest(
        {
          url: 'login',
          method: 'post',
          data: values
        },
        (data, error) => {
          if (error) return setErrors(error);
          const token = data.result.access_token;
          const user = data.result.profile;
          localStorage.setItem('@token', token);

          dispatch(storeToken(token));
          dispatch(saveUserInfo(user));
          navigate(data.result.go_settings ? '/settings' : '/', { replace: true });
          generateAlert(data.message, 'success');
          formikHelpers.resetForm();
        }
      ).finally(() => setDisabled(false));
    }
  });

  return (
    <form
      className="space-y-3"
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label
          htmlFor="email"
          className="form-label"
        >
          {t('email')}
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@example.com"
          className="form-input form-outline !bg-gray-50"
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('email_mobile', target.value)
          }
        />
        {errors?.email_mobile ? <span className="form-error">{errors?.email_mobile}</span> : null}
      </div>
      <div className="form-group">
        <label
          htmlFor="password"
          className="form-label"
        >
          {t('password')}
        </label>
        <input
          type="password"
          name="password"
          id="password"
          placeholder="••••••"
          className="form-input form-outline !bg-gray-50"
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('password', target.value)
          }
        />
        {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
      </div>
      <div className="!mt-8 space-y-2">
        <button
          className="btn-with-icon bg-primary w-full !p-4"
          type="submit"
          disabled={disabled}
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('sign-in')}</span>
          )}
        </button>
      </div>
    </form>
  );
};

const Login: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <div className="col-span-full">
        <div className="flex flex-col h-full mx-auto">
          <div className="flex items-center justify-center w-full h-full my-6 m-auto">
            <Card className="max-w-xl sm:!p-8">
              <Link
                to="/"
                className="table mx-auto"
              >
                <img
                  src="/logo.png"
                  alt="website logo"
                  className="w-full max-w-[15rem] object-contain"
                />
              </Link>
              <p className="text-sm text-gray-500 font-medium mt-4 mb-6 text-center ">
                {t('login-content')}
              </p>
              <FormBody />
            </Card>
          </div>
          <Footer />
        </div>
      </div>
    </AuthWrapper>
  );
};

export default Login;
